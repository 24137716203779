/**
 * @description the card is used in employee dashboard,
 * it displays the restricted count number
 */
import * as React from "react"
// import {css} from "@emotion/core"

import moduleStyles from "./content-placeholder.module.scss"

/**
 * @typedef {object} OwnProps
 * @property {string=} color
 * @property {boolean=} circle to use circle, must provide a number width
 * @property {number=} baseWidthPercentage
 * @property {number|string=} height
 * @property {number|string=} width
 *
 * @typedef {React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>} RawSpanProps
 * @typedef {OwnProps & RawSpanProps } ContentPlaceholderProps
 */

const styleNumber = number => {
  if (typeof number === "number") return `${number}px`
  return number
}

/**
 * @type {React.FunctionComponent<ContentPlaceholderProps>}
 * @param {ContentPlaceholderProps} param0
 */
const ContentPlaceholder = ({
  color,
  baseWidthPercentage = 30,
  width,
  height,
  circle = false,
  style,
  className,
  ...props
}) => {
  const isCircleable = circle && typeof width === "number"

  let customStyle = {
    backgroundColor: color,
    width: width
      ? styleNumber(width)
      : `${Math.floor(Math.random() * 51) + baseWidthPercentage}%`,
    height: isCircleable ? styleNumber(width) : styleNumber(height),
    borderRadius: isCircleable ? styleNumber(width / 2) : undefined,
    ...style,
  }
  return (
    <span
      {...props}
      className={`${moduleStyles.contentPlaceholder} ${className || ""}`}
      style={customStyle}
    />
  )
}

export default ContentPlaceholder
