import * as React from "react"
import {css} from "@emotion/core"

import {Spacing} from "./spacing"
import {IconButton} from "./icon-button"

/**
 * @typedef {object} RadioOwnProps
 * @property {boolean=} active
 *
 * @typedef {React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLDivElement>, HTMLDivElement>} RawDivProps
 * @typedef {RadioOwnProps & RawDivProps} RadioProps
 */

/**
 * @type {React.FunctionComponent<RadioProps>}
 */
export const Radio = ({active, children, ...props}) => {
  return (
    <div
      {...props}
      className="row component-base-icon-button-parent"
      css={css(`justify-content: center; cursor: default`)}>
      <IconButton
        icon={
          active ? "btn-radio-button-selected" : "btn-radio-button-unselected"
        }
        size={20}
      />
      <Spacing spacing="none" width={10} />
      {children}
    </div>
  )
}
