import * as React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import "./text-field.scss"

let textFieldsCounter = 0

/**
 * @typedef {object} TextFieldOwnProps
 * @property {string|React.ReactElement=} error
 * @property {string} label
 * @property {boolean=} required
 * @property {React.ReactElement} rightLabelElement
 * @property {React.ReactElement} endAdornment a suffix or an action to an input; For instance, you can use an icon button to hide or reveal the password.
 *
 * @typedef {React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>} RawInputProps
 * @typedef {RawInputProps & TextFieldOwnProps} TextFieldProps
 */

/**
 * @type {React.ForwardRefRenderFunction<HTMLInputElement, TextFieldProps>}
 */
const TextFieldForwardRefRender = (
  {
    label,
    error,
    className,
    id,
    required = false,
    endAdornment,
    rightLabelElement,
    type = "text",
    ...props
  },
  ref,
) => {
  const inputLabel = label || props["aria-label"]
  const inputId = React.useRef(id || textFieldsCounter++)

  let errorMsg =
    error || (required && !props.value ? "mandatory input" : undefined)

  return (
    <div
      className={classNames(className, "component-base-text-field-container")}>
      {/* the label row */}
      <div className="row label-row">
        <label
          htmlFor={`text-field-${inputId.current}`}
          className={classNames("text-box-label", {
            "text-inactive-color": props.disabled,
          })}>
          {inputLabel}
          {required ? <span className="text-error-color">*</span> : null}
        </label>
        {rightLabelElement}
      </div>

      {/* the input & the input adornment */}
      <div className="p-relative">
        <input
          className={classNames("component-base-text-field", {
            "has-error": !!errorMsg,
          })}
          type={type}
          {...props}
          id={`text-field-${inputId.current}`}
          ref={ref}
        />
        <div className="end-adornment">{endAdornment}</div>
      </div>

      {/* error message */}
      <span className="error">{errorMsg || "  "}</span>
    </div>
  )
}

/**
 * @type {React.ForwardRefExoticComponent<TextFieldProps>}
 */
export const TextField = React.forwardRef(TextFieldForwardRefRender)

TextField.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  label: PropTypes.string,
  rightLabelElement: PropTypes.element,
  endAdornment: PropTypes.element,
}

TextField.defaultProps = {}
