import * as React from "react"
import { Router } from "@reach/router"
import Loadable from "@loadable/component"
import { configureAmplify } from "../lib/aws/configure"

import DashboardLayout from "../app-layouts/dashboard-layout";

const ROLE = 'role';
const USER_CACHED_KEY = process.env.GATSBY_AUTH_USER


const LoadableEmployeePage = Loadable(() =>
  import(/* webpackPrefetch: true */ "./app-employees"),
)

const LoadableWorkspacePage = Loadable(() =>
  import(/* webpackPrefetch: true */ "./app-workspace"),
)

const LoadableContactDetailPage = Loadable(() =>
  import(/* webpackPrefetch: true */ "./app-contact-detail"),
)

const LoadableSettingsPage = Loadable(() =>
  import(/* webpackPrefetch: true */ "./app-settings"),
)

const LoadableTestPage = Loadable(() =>
  import(/* webpackPrefetch: true */ "./test-add-workspace"),
)

configureAmplify()

function AppRoute() {
  if(window.localStorage.getItem(USER_CACHED_KEY)){
  let superAdminLoggedin = false;

  var role = window.localStorage.getItem(ROLE);

  if (role !== null && role.includes('SUPERADMIN')) {
    superAdminLoggedin = true
  } else {
    superAdminLoggedin = false;

  }
  console.log(superAdminLoggedin)
  return (
    <DashboardLayout>
      <Router>

        {
          superAdminLoggedin === true && window.location.pathname === "/app/workspace"?
            <LoadableTestPage default path="/workspaces" />

            :

            <LoadableEmployeePage default path="/employee" />
        }

        <LoadableContactDetailPage path="/employee/ct" />
        <LoadableSettingsPage path="/settings" />
		

      </Router>
    </DashboardLayout>
  );
      }
      else {
        return <div>Error!!</div>;
      }
}

export default AppRoute
