import * as React from "react"

import IC_ARROW_DOWN from "../../images/ic-arrow-down.svg"

import "./drop-down.scss"

var classNames = require("classnames")

/**
 * @typedef {object} DropDownOption
 * @property {any} id
 * @property {string} title
 * @property {string=} selectedClass
 *
 * @typedef {object} DropDownOwnProps
 * @property {DropDownOption[]} options
 * @property {any} selectedId
 * @property {(option: DropDownOption) => void} onOptionSelect
 *
 * @typedef {React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>} InputProps
 * @typedef {DropDownOwnProps & InputProps} DropDownProps
 */

/**
 * @type {React.FunctionComponent<DropDownProps>}
 */
export const DropDown = ({
  options = [],
  selectedId = options.length ? options[0].id : undefined,
  onOptionSelect,
  defaultTitle = "Select...",

  className,
  ...props
}) => {
  const [focused, setFocused] = React.useState(props.autoFocus)

  const selectedOption = options.find(o => o.id === selectedId)
  const title = selectedOption?.title || defaultTitle

  return (
    <div
      className={classNames("component-base-drop-down", {
        show: focused,
      })}
      role="button"
      tabIndex="0"
      onMouseDown={() => {
        if (!props.disabled) {
          setFocused(f => !f)
        }
      }}
      onBlur={() => setFocused(false)}>
      <div className="p-relative">
        <input
          value={title}
          readOnly
          {...props}
          className={classNames(
            className,
            selectedOption?.selectedClass || selectedOption?.id,
          )}
        />
        <img width={22} height={22} src={IC_ARROW_DOWN} alt={IC_ARROW_DOWN} />
      </div>
      <div className="dropdown-content-container">
        <div
          role="button"
          tabIndex="0"
          className="dropdown-content"
          onMouseDown={e => {
            if (e.target.tagName === "BUTTON" && !props.disabled) {
              onOptionSelect && onOptionSelect(options[parseInt(e.target.name)])
            }
          }}>
          {options.map((option, index) => (
            <button
              className={`option ${selectedId === option.id ? "selected" : ""}`}
              name={index}
              key={option.id}>
              {option.title}
            </button>
          ))}
        </div>
      </div>
    </div>
  )
}
