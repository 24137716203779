import * as React from "react"
import {Redirect} from "@reach/router"
import {isLoggedIn} from "../services/auth"

/**
 * @typedef {object} ProtectedRoute
 * @property {React.Component} as
 *
 * @typedef {import('gatsby').PageProps<object, object>} PageProps
 */

/**
 * @type {React.FunctionComponent<ProtectedRoute & PageProps>}
 */
const ProtectedRoute = ({as: Component, ...pageProps}) => {
  
  if (!isLoggedIn()  ) {

    return (

      <Redirect
        noThrow
        to="/signin-account/"
        state={{
          redirectURL: '/signin-account',
        }}
      />
    )
  }

  else
  {

  return <Component {...pageProps} />
  }
}

export default ProtectedRoute
