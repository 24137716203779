import * as React from "react"
import { Link, navigate } from "gatsby"

import IMG_LOGIN from "../images/img-login.svg"
import { Spacing } from "../components/base/spacing"
import { TextField } from "../components/base/text-field"
import { Button } from "../components/base/button"
import { useInput } from "../hooks/inputs"
import { useMutation } from "../hooks/react-query/useMutationV2"

import { emailValidator } from "../lib/validator/email"
import { setUser } from "../services/auth"

import AuthLayout from "../layouts/auth-layout"
import {isLoggedIn} from "../services/auth"

const lazyLoadSignInAccount = async (...args) => {
  const modules = await Promise.all([
    import("../lib/aws/configure"),
    import("../lib/smartworks-api/auth"),
  ])

  modules[0].configureAmplify()
  return modules[1].signInAccount(...args).then(currentUser => {
    console.log(currentUser);
    setUser(currentUser.user)
  })
}

/**
 * @typedef {import('./signin').PageProps} PageProps
 */

/**
 * @type {React.FunctionComponent<PageProps>}
 * @param {PageProps} props
 */
const SignInAccountPage = props => {

  const isSuperAdmin = () => {
    var role = window.localStorage.getItem('role');
    return (role ? role.includes('SUPERADMIN') : false)
  }

  if (isLoggedIn()) {
    navigate(isSuperAdmin() ? "/app/workspace" : "/app/employee");
  }

  // get workspace string from url params or location state

  const emailInput = useInput({ defaultValue: "", validator: emailValidator })
  const passwordInput = useInput({ defaultValue: "" })

  // signin hook
  const [signIn, { isLoading, error }] = useMutation(lazyLoadSignInAccount, {
    onSuccess: (_data, variables) => {

      // console.log("success", _data, props.location.state)
      //navigate(props && props.location && props.location.state && props.location.state.redirectURL || "/app/workspace")
      navigate(isSuperAdmin() ? "/app/workspace" : "/app/employee")
    },
    onError: (err) => {
      console.log(err);
    }
  })

  const invalidInputs = emailInput.error || !passwordInput.value

  const onSubmitHandler = e => {
    e.preventDefault && e.preventDefault()

    // page prefetch
    if (!props.location.pathname.startsWith("/app/")) {
      // console.log("page prefetch", props.location)
      /* global ___loader */
      const enqueue = window.___loader ?.enqueue || ___loader ?.enqueue
      enqueue && enqueue("/app")
    }

    if (invalidInputs) { return null; }
    signIn({
      workspace: emailInput.value,
      email: emailInput.value.toLowerCase(),
      password: passwordInput.value,
    })
  }

  return (
    <AuthLayout bannerImg={IMG_LOGIN} seoTitle="Sign in your account">
      <h1>Sign in your account</h1>
      <Spacing spacing="none" height={68} />

      <form onSubmit={onSubmitHandler}>
        <TextField
          {...emailInput.getProps({
            autoComplete: "username",
            type: "email",
            "aria-label": "Email",
            tabIndex: "0",
            disabled: isLoading,
          })}
        />
        <Spacing spacing="xxl" />

        <TextField
          rightLabelElement={
            <Link
              to="../forgot-password"
              state={{ email: emailInput.value }}>
              <button className="label-text-button" type="button">
                Forgot Password?
              </button>
            </Link>
          }
          {...passwordInput.getProps({
            autoComplete: "current-password",
            type: "password",
            "aria-label": "Password",
            tabIndex: "0",
            disabled: isLoading,
            error: error && !isLoading ? error.message : undefined,
          })}
        />
        <Spacing spacing="xxl-2" />
        <Button
          type="submit"
          block
          tabIndex="0"
          disabled={invalidInputs}
          loading={isLoading}
          onClick={onSubmitHandler}>
          Sign In
        </Button>
      </form>
    </AuthLayout>
  )
}

export default SignInAccountPage
