import * as React from "react"
import classNames from "classnames"

import {Button} from "./button"
import "./button-outlined.scss"

/**
 * @typedef {object} OutlinedButtonOwnProps
 * @property {"small"|"normal"=} size
 * @property {"red"=} color
 *
 * @typedef {import('./button').ButtonProps & OutlinedButtonOwnProps} OutlinedButtonProps
 */

/**
 * @type {React.ForwardRefRenderFunction<HTMLButtonElement, OutlinedButtonProps>}
 */
export const OutlinedButtonForwardRefRender = (
  {size = "normal", color = "", ...props},
  ref,
) => {
  return (
    <Button
      {...props}
      className={classNames("outlined", {
        [`outlined-${color}`]: true,
        [`outlined-size-${size}`]: true,
      })}
      ref={ref}
    />
  )
}

/**
 * @type {React.ForwardRefExoticComponent<OutlinedButtonProps>}
 */
export const OutlinedButton = React.forwardRef(OutlinedButtonForwardRefRender)
