import * as React from "react"
import {navigate} from "gatsby"

import {Spacing} from "./base/spacing"
import {IconButton} from "./base/icon-button"
import {Icon} from "./base/icon"
import {useToggle} from "../hooks/toggle"
import {getWorkspace, logout, getEmail} from "../services/auth"
import {useToast} from "../lib/toast"

import IC_WORKSPACE from "../images/ic-workspace.svg"

import NavigationMenu from "./navigation-menu"

const APP_PROFILE_ICON_ID = "app-profile-icon"

/**
 * @type {React.FunctionComponent<any>}
 */
const AppHeader = () => {
  const toast = useToast()

  const reConnect = () => {
    toast("Reconnecting.. ", {
      type: "success",
      id: "Reconnecting",
    });
    window.location.reload(true);
  }

  const noNetwork = () => {
    if (!navigator.onLine) {
      toast("Please check your network connection..", {
        type: "error",
        id: "Internet Disconnected",
      });
    }
  }

  const checkUserSession = () => {
    window.setTimeout(() => {
      let sessionStart = new Date(parseInt(window.localStorage.getItem("sessionstart"), 10));
      let expiration = sessionStart.setMinutes(sessionStart.getMinutes() + 15);
      let now = parseInt(Date.now(), 10);

      if (expiration < now) {
        if (document.hasFocus() && confirm("Session Expired. Do you wish to continue?")) {
          window.localStorage.setItem("sessionstart", Date.now());
        } else {
          logout();
        }
      }
      checkUserSession();
    }, 5000)
  }

  window.addEventListener("online", reConnect);

  window.addEventListener("offline", noNetwork);

  noNetwork(); //call on page load..

  checkUserSession();

  const {show: showLogout} = useToggle({id: APP_PROFILE_ICON_ID})
  let superAdminLoggedin = false;
      const ROLE = 'role'
      var role = window.localStorage.getItem(ROLE);
      console.log(role);
      if (role !== null && role.includes('SUPERADMIN')) {
        superAdminLoggedin = true
      }
  return (
    <>
      <div className="sw-app-header">
        <div>
        { superAdminLoggedin && window.location.href.indexOf("employee") != -1 ?
          <IconButton
            icon="btn-back"
            aria-label="Back"
            size={30}
            onClick={() => navigate("/app/workspace")}
		/> :""}
          <NavigationMenu />
         
          <Spacing width={32} />
          <img height={40} width={40} src={IC_WORKSPACE} alt={IC_WORKSPACE} />
          <Spacing spacing="l" />
          <span className="title">{superAdminLoggedin ? getEmail() : getWorkspace()}</span>

        { superAdminLoggedin && window.location.href.indexOf("employee") != -1 ?
            <><Spacing spacing="none" width={30} /> {"Workspace: " + getWorkspace()} </> : ""}
        </div>
        <div>
		{ !superAdminLoggedin ?
          <IconButton
            icon="btn-settings"
            aria-label="open dialog for settings"
            size={30}
            onClick={() => navigate("/app/settings")}
		/> :""}
          <Spacing spacing="none" width={30} />
          <div className="sw-dropdown inline-flex">
            <IconButton
              icon="btn-profile"
              id={APP_PROFILE_ICON_ID}
              aria-label="open logout button"
              size={30}></IconButton>
            <div className={`dropdown-menu logout ${showLogout ? "show" : ""}`}>
              <button className="row" onClick={logout}>
                <Icon icon="ic-logout" size={20} />
                <span className="ml-xxs">Logout</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AppHeader
