import * as React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import {useMutation} from "../../hooks/react-query/useMutationV2"
import {uploadToS3} from "../../lib/upload-s3"

import "./text-field.scss"
import "./file-input.scss"

import {colors} from "../../styles/theme"

let textFieldsCounter = 0

/**
 * @typedef {object} FileInputOwnProps
 * @property {string|React.ReactElement=} error
 * @property {string} label
 * @property {number=} maxSize (in MB)
 * @property {(param: {file: File}) => void=} onFileSelected
 * @property {boolean=} s3
 *
 * @typedef {React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>} RawInputProps
 * @typedef {RawInputProps & FileInputOwnProps} FileInputProps
 */

/**
 * @type {React.ForwardRefRenderFunction<HTMLInputElement, FileInputProps>}
 */
const FileInputForwardRefRender = (
  {
    label = "File Name",
    error,
    maxSize = 2,
    s3 = true,
    className,
    id,
    onFileSelected,
    type = "file",
    disabled,
    ...props
  },
  ref,
) => {
  const inputLabel = label || props["aria-label"]
  const inputId = React.useRef(id || `file-input-${textFieldsCounter++}`)

  /**@type [File, React.Dispatch<React.SetStateAction<File>>] */
  const [selectedFile, setSelectedFile] = React.useState()
  const [fileUploaded, setFileUploaded] = React.useState(false)

  // upload file hook
  const [
    runUploadToS3,
    {isLoading: isUploadLoading, error: uploadError},
  ] = useMutation(uploadToS3, {
    onMutate: variable => {
      setSelectedFile(variable.file)
      setFileUploaded(false)
      // reset the file selected, because file changing
      onFileSelected &&
        onFileSelected({
          file: undefined,
        })
    },
    onSuccess: (_data, variable) => {
      setFileUploaded(true)
      onFileSelected &&
        onFileSelected({
          file: variable.file,
        })
    },
  })

  const fileError = uploadError ? uploadError.message : error || undefined

  return (
    <div className={`${className || ""} component-base-text-field-container`}>
      {/* the label row */}
      <div className="row label-row">
        <label htmlFor={inputId.current}>{inputLabel}</label>
        <span className="text-field-right-label">
          Max file size : {maxSize}MB
        </span>
      </div>

      {/* the input & the input adornment */}
      <div className="p-relative">
        <input
          type={type}
          style={{
            position: "absolute",
            overflow: "hidden",
            zIndex: -1,
            opacity: 0,
            height: 0,
            width: 0,
          }}
          {...props}
          disabled={disabled || isUploadLoading}
          id={inputId.current}
          ref={ref}
          onChange={event => {
            // not select anything
            if (!event.target.files[0]) {
              return
            }

            runUploadToS3({file: event.target.files[0], maxSize, toS3: s3})
          }}
        />
        <label
          htmlFor={inputId.current}
          className={classNames("component-base-text-field", {
            "file-input-fake-label": !disabled,
          })}
          style={{
            display: selectedFile ? "flex" : "block",
            alignItems: "center",
            backgroundColor: fileError ? colors.pale : undefined,
            color: fileError ? colors.black : undefined,
            padding: "0 10px",
          }}>
          {selectedFile?.name}
          <div className="end-adornment">
            <div
              className="upload-text-button"
              aria-disabled={disabled || isUploadLoading}
              style={{
                color:
                  disabled || isUploadLoading ? colors.disabled : undefined,
              }}>
              Upload{fileUploaded ? "ed!" : isUploadLoading ? "ing..." : ""}
            </div>
          </div>
        </label>
      </div>

      {/* error message */}
      <span className="error">{fileError || "  "}</span>
    </div>
  )
}

/**
 * @type {React.ForwardRefExoticComponent<FileInputProps>}
 */
export const FileInput = React.forwardRef(FileInputForwardRefRender)

FileInput.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  label: PropTypes.string,
}

FileInput.defaultProps = {}
