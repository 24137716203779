import PropTypes from "prop-types"
import styled from "@emotion/styled"

import {colors, spacings} from "../../styles/theme"

/**
 * @typedef {object} TextButtonOwnProps
 * @property {boolean=} noPadding
 *
 * @typedef {React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>} RawButtonProps
 * @typedef {RawButtonProps & TextButtonOwnProps} TextButtonProps
 */

/**
 * @type {React.FunctionComponent<TextButtonProps>}
 */
export const TextButton = styled.button`
  border: 0;
  border-style: none;
  outline: none;
  background-color: transparent;
  color: ${colors.butterscotch};
  &:disabled {
    color: ${colors.disabled};
  }
  font-size: 14px;
  font-weight: 500;
  padding: ${props => (props.noPadding ? "0" : `6px ${spacings["s"]}px`)};

  &:hover:enabled {
    color: ${colors.lightMustard};
  }

  &:active:enabled {
    color: #dc8810;
    text-shadow: none;
  }
`

TextButton.propTypes = {
  noPadding: PropTypes.bool,
}
TextButton.defaultProps = {
  noPadding: false,
}
