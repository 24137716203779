import AWS from "aws-sdk"
import {getUser} from "../services/auth"

/**
 * @typedef {object} UploadToS3Params
 * @property {File} file
 * @property {number=} maxSize in MB, (default 2 )
 * @property {boolean=} toS3 default true,
 */

/**
 * @param {UploadToS3Params} param0
 * @returns {Promise<AWS.S3.ManagedUpload.SendData>}
 */
export async function uploadToS3({file, maxSize = 2, toS3 = true}) {
  if (file.size > maxSize * 1024 * 1024) {
    let error = new Error(`File size exceeds ${maxSize}MB`)
    error.response = {
      status: 400,
      headers: {},
      statusText: "400",
      data: {
        errorMessage: error.message,
      },
    }
    error.name = "RequestError"
    throw error
  }
  if (!toS3) {
    return {
      status: 200,
      data: {},
      statusText: "200",
    }
  }
  const accessKey = process.env.GATSBY_ACCESS_KEY
  const secretAccessKey = process.env.GATSBY_ACCESS_SECRET
  const cred = {
    accessKeyId: accessKey,
    secretAccessKey: secretAccessKey,
  }

  AWS.config.update(cred)
  // console.log("------cred", cred)

  let workspaceKey = encodeURIComponent(getUser().workspace) + "/"
  let fileKey = workspaceKey + file.name
  // console.log(`-------${fileKey}`)

  let upload = new AWS.S3.ManagedUpload({
    params: {
      Bucket: process.env.GATSBY_S3_UPLOAD_BUCKET,
      Key: fileKey,
      Body: file,
    },
  })

  return upload.promise()
}
