import React, {Component} from "react"
import LogoHeader from "../components/logo-header"
import Footer from "../components/footer"
import "./verification-failed.scss"
import IMG_ERROR from "../images/ic-verified-error.svg"
import {Button as PrimaryButton} from "../components/base/button"
import {navigate} from "gatsby"
import SEO from "../layouts/seo"

export default class verificationFailed extends Component {
  state = {}
  constructor(props) {
    super(props)
    this.handleSignup = this.handleSignup.bind(this)
  }

  handleSignup() {
    navigate("/signin-account")
  }

  render() {
    return (
      <div className="verify-failed-container">
        <SEO title="Verified Failed" />
        <LogoHeader />
        <div className="verify-failed-content">
          <img className="ic-sign-up-failed" src={IMG_ERROR} alt={IMG_ERROR} />
          <div className="header">Verification Failed</div>
          <div className="subtitle">
            Oops, the workspace you entered might already be taken during the
            verification process. Please create a new workspace. Will redirect
            to create workspace page or click here.
          </div>
          <PrimaryButton
            onClick={this.handleSignup}
            title="Sign Up"
            width={440}>
            Sign Up
          </PrimaryButton>
        </div>
        <Footer />
      </div>
    )
  }
}
