import {useReducer, useCallback, useMemo} from "react"

import {useDefaultOnErrorHandler} from "./utils"

/**
 * @typedef {object} UseMutationV2Result
 * @property {boolean} isLoading
 * @property {import('react-query').MutationStatus} status
 * @property {Error} error
 * @property {()=>void} reset
 */

/**
 * @type {UseMutationState}
 */
const initialState = {
  isLoading: false,
  status: "idle",
  error: undefined,
}

/**
 * @param {UseMutationState} state
 * @param {UseMutationAction} action
 * @returns {UseMutationState}
 */
function reducer(state, action) {
  switch (action.type) {
    case "start":
      return {
        ...state,
        isLoading: true,
        status: "loading",
      }
    case "success":
      return {
        ...state,
        error: undefined,
        isLoading: false,
        status: "success",
      }
    case "fail":
      return {
        ...state,
        error: action.error,
        isLoading: false,
        status: "error",
      }
    case "reset":
      return {
        isLoading: false,
        error: undefined,
        status: "idle",
      }
    default:
      return state
  }
}

/**
 * @template TData, TVariables
 * @param {import('react-query').MutationFunction<TData, TVariables>} mutationFn
 * @param {import('react-query').UseMutationOptions=} options
 * @returns {[(params: TVariables) => Promise<TData>, UseMutationV2Result]}
 */
export function useMutation(mutationFn, options) {
  const [state, dispatch] = useReducer(reducer, initialState)

  const onErrorHandler = useDefaultOnErrorHandler(options.onError, {
    autoLogoutWhenAuthFail: false,
  })

  const mutate = async variable => {
    let error, response
    dispatch({
      type: "start",
    })
    options.onMutate && options.onMutate(variable)
    try {
      response = await mutationFn(variable)
      dispatch({
        type: "success",
      })
      options.onSuccess && options.onSuccess(response, variable)
    } catch (err) {
      error = err
      dispatch({
        type: "fail",
        error,
      })
      onErrorHandler(error)
    }
    options.onSettled && options.onSettled(response, error, variable)
  }

  const reset = useCallback(() => {
    dispatch({
      type: "reset",
    })
  }, [dispatch])

  const useMutationV2Result = useMemo(
    () => ({
      ...state,
      reset,
    }),
    [reset, state],
  )

  return [mutate, useMutationV2Result]
}

/**
 * @typedef {object} UseMutationState
 * @property {boolean} isLoading
 * @property {import('react-query').MutationStatus} status
 * @property {Error} error
 */
/**
 * @typedef {object} UseMutationAction
 * @property {'reset' | 'start' | 'success' | 'fail' } type
 * @property {error=} error
 */
