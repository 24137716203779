import styled from "@emotion/styled"

import {spacings} from "../../styles/theme"

export const ModalContainer = styled.div`
  display: ${props => (props.hidden ? "none" : "flex")};
  justify-content: center;
  align-items: center;

  & > .modal-content {
    margin-top: auto;
    margin-bottom: auto;

    display: block;
    position: relative;
    width: 600px;
    background-color: white;
    border-radius: 15px;
    padding: ${spacings["xxl-3"]}px;
    box-shadow: 20px 20px 50px 0 rgba(0, 0, 0, 0.15);
  }

  & .modal-close-btn {
    position: absolute;
    right: ${spacings["xxl-3"]}px;
    cursor: pointer;
  }
`

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;

  font-weight: 600;
  color: #36383b;
  font-size: 20px;
`

export const ModalBody = styled.div`
  margin-top: 30px;
`

export const ModalFooter = styled.div`
  margin-top: 30px;

  &.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`
