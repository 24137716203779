import * as React from "react"
import {Link, navigate} from "gatsby"

import IMG_FORGOT_PASSWORD from "../images/img-forgot-password.svg"
import {Spacing} from "../components/base/spacing"
import {TextField} from "../components/base/text-field"
import {Button} from "../components/base/button"

import {useInput} from "../hooks/inputs"
import {emailValidator} from "../lib/validator/email"
import {useMutation} from "../hooks/react-query/useMutationV2"
import {useSignInWorkspace} from "../hooks/gatsby-page"

import AuthLayout from "../layouts/auth-layout"

const lazyLoadForgotPassword = async (...args) => {
  const modules = await Promise.all([
    import("../lib/aws/configure"),
    import("../lib/smartworks-api/auth"),
  ])

  modules[0].configureAmplify()
  return modules[1].forgotPassword(...args)
}

/**
 * @typedef {import('./signin').PageProps} PageProps
 */

/**
 * @type {React.FunctionComponent<PageProps>}
 * @param {PageProps} props
 */
const ForgotPasswordPage = props => {
  // get workspace string from url params or location state as default value

  
  const emailInput = useInput({
    defaultValue: props.location.state?.email || "",
    validator: emailValidator,
  })

  // forget password hook
  const [runForgotPassword, {isLoading, error}] = useMutation(
    lazyLoadForgotPassword,
    {
      onSuccess: (_data, variables) => {
        navigate(`../reset-password`, {
          state: variables,
        })
      },
    },
  )

  const invalidInputs = emailInput.error 

  const onSubmitHandler = e => {
    e.preventDefault && e.preventDefault()
    if (invalidInputs) return
    runForgotPassword({
      username: emailInput.value,
    })
  }

  return (
    <AuthLayout bannerImg={IMG_FORGOT_PASSWORD}>
      <h1>Forgot your password?</h1>
      <Spacing spacing="m" />
      <p>
        Ｗe'll send a verification code to the email address you used to reset
        password.
      </p>
      <Spacing spacing="none" height={70} />

      <form onSubmit={onSubmitHandler}>
       
        <Spacing spacing="xxl" />
        <TextField
          {...emailInput.getProps({
            "aria-label": "Email",
            tabIndex: "0",
            error: error ? error.message : undefined,
            disabled: isLoading,
          })}
        />
        <Spacing spacing="xxl-2" />
        <Button
          type="submit"
          block
          tabIndex="0"
          loading={isLoading}
          disabled={invalidInputs}
          onClick={onSubmitHandler}>
          Send Email
        </Button>
      </form>

      <footer>
        <p>
          Back to{" "}
          <Link
            to="../signin-account"
            state={{email: emailInput.value}}>
            Sign in
          </Link>
        </p>
        <Spacing spacing="m" />
        <p>
          Already got the verification code?{" "}
          <Link
            to="../reset-password"
            state={{email: emailInput.value}}>
            Reset Password
          </Link>
        </p>
      </footer>
    </AuthLayout>
  )
}

export default ForgotPasswordPage
